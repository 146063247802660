import React, { createContext, ReactNode, useEffect, useState } from 'react'

interface IState {
  isDisplayInfo: boolean
  setIsDisplayInfo: (value: boolean) => void
  isDarkMode: boolean
  setIsDarkMode: (value: boolean) => void
}

const defaultState: IState = {
  isDisplayInfo: true,
  setIsDisplayInfo: () => undefined,
  isDarkMode: true,
  setIsDarkMode: () => undefined,
}

const LOCAL_STORAGE_KEYS = {
  THEME: 'theme',
  INFO: 'info',
}

export const ThemeContext = createContext(defaultState)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [isDisplayInfo, setIsDisplayInfo] = useState<boolean>(false)
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false)

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.THEME) === 'dark') {
      setIsDarkMode(true)
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.INFO) === 'true') {
      setIsDisplayInfo(true)
    }

    window.addEventListener('keydown', keyDownHandler)

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.THEME,
      isDarkMode ? 'dark' : 'light'
    )

    if (isDarkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [isDarkMode])

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.INFO, isDisplayInfo ? 'on' : 'off')
  }, [isDisplayInfo])

  const keyDownHandler = ({ code }: KeyboardEvent) => {
    switch (code) {
      case 'KeyI':
        handleSetDisplayInfo(
          !(localStorage.getItem(LOCAL_STORAGE_KEYS.INFO) === 'on')
        )
        break
      case 'KeyT':
        const themeType = localStorage.getItem(LOCAL_STORAGE_KEYS.THEME)
        handleSetDarkMode(!(themeType === 'dark'))
        break
      default:
        break
    }
  }

  const handleSetDisplayInfo = (value: boolean) => {
    setIsDisplayInfo(value)
  }

  const handleSetDarkMode = (value: boolean) => {
    setIsDarkMode(value)
  }

  return (
    <ThemeContext.Provider
      value={{
        isDisplayInfo,
        isDarkMode,
        setIsDisplayInfo: handleSetDisplayInfo,
        setIsDarkMode: handleSetDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
